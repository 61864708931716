import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairReplacementMen = () => {

  const seoData = {
    title: 'Hair Replacement For Men in Delhi - Radiance Hair Studio',
    description: 'Experience top-quality hair replacement for men in Delhi at Radiance Hair Studio. Regain your confidence with natural-looking results. Book an appointment now. ',
    keywords: ['Hair Replacement For Men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Replacement-System-for-Men's.jpg",
      "https://www.radiancehairstudio.com/hair-replacement-for-men2.png",
      "https://www.radiancehairstudio.com/hair-replacement-for-men3.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "serviceType": "Hair Replacement System for Men's",
    "disambiguatingDescription": "Hair Replacement For Men in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/hair-replacement-for-men.php",
    "category": "https://www.radiancehairstudio.com/hair-replacement-for-men.php",
    "description": "Experience top-quality hair replacement for men in Delhi at Radiance Hair Studio. Regain your confidence with natural-looking results. Book an appointment now."
  };    

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Replacement-System-for-Men's.jpg",
      content: (
        <div>
          <h2>Hair Replacement for Men's</h2>
          <p>
            The best way to deal with your baldness, semi-permanent hair loss and
            even accept a frequent change of hairstyles is to go for <a href="/gallery.php" style={{ color: "#373737"}}>hair wigs</a> in
            Delhi. You will have a number of choices at your hand in the case.
            Over the above, you will not experience any sort of side-effects.
          </p>
  
          <h2>Radiance’s Masterstroke</h2>
          <p>
            Radiance Hair Salon is the perfect name that stands at this level,
            dedicated supporting on its experienced professionals, to serve you in
            the best possible manner. We will not only show our catalog and give
            you the options, but we love to guide our clients for the best style,
            they must get for the amount they will be spending.
          </p>
          <p>
            Every man has his own choice and his face cutting suggests a
            particular pattern of Hair Replacement in Delhi. Thus, it is essential
            to provide you with the perfect guide in this matter, ensuring the
            worth of your money.
          </p>
          <p>
            At Radiance, we ensure the same with all patterns of wigs, extensions,
            and replacements applied externally. The methods we use are completely
            painless and you can find an instant result for any of those methods.
            Replacement with 100% original human hair patches and completely safe
            glues, clips, and tapes will give you a very natural look and feel as
            well. Moreover, our certified professionals are always ready to guide
            you in the full process so that you can remain really happy with the
            new look.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-replacement-for-men2.png",
      largeImg: "/hair-replacement-for-men3.png",
      content: (
        <div>
          <h2>Benefits of Hair Replacement Systems</h2>
  
          <ul    >
            <li>
  
              The style is surely the need of yours, which you are feeling shy to
              apply on your natural hairs. Get the male wigs or hair extensions
              and ensure that your style is latest. If you are suffering from
              baldness or semi-permanent hair loss, then also there is nothing to
              fear. Just apply the extensions or wigs and enjoy the grand and
              smart look in few minutes.
            </li>
            <li>
  
              <a href="/hair-extensions-for-men.php" style={{ color: "#373737"}}>Hair extensions</a> are all to be applied externally. Hence there
              remains no chance of the type of side-effects. Glues and tapes are
              applied on your scalp, but those are of international quality.
              Hence, there is nothing to be afraid of the adhesives used in the
              process.
            </li>
            <li>
  
              Your natural hair will always remain intact and harmless. The
              extensions are applied from the scalp and adhesives or tapes are
              applied on the scalp itself and hence your natural hair roots remain
              completely away from all the chemicals. So, no chance of any hair
              damage, by any means.
            </li>
            <li>
  
              You can apply shampoos, conditioners and even serums on the
              extensions. In the case of clip-on, you can even open those at times
              and wear them, when you need. So, it’s completely hassle-free, in
              terms of use too.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs of Hair Replacement Systems",
      description:
        "We know that it is not very easy to go for a complete transformation process. Many questions might have come to your mind while thinking to go for a hair replacement. That’s why here we tried to answer some them.",
      description2: "",
      faq: [
        {
          title: "Why shall I go for this replacement?",
          description:
            "There are three conditions when you must go for these methods – having a bald head, facing the loss of hair and looking for a style statement. In all the cases, apply this hair styling method includes wig pasting. International standard glue or both sided tapes are used for the method, and in each case, there remains no side effect at all for any Hair Replacement in Delhi.",
        },
        {
          title: "Is there any side effect?",
          description:
            "All the processes that are applied by Radiance are external and hence there remains no chance of side-effects. Few types of glue or tapes are used, but those are non-effecting for your skull.",
        },
        {
          title: "Can there be any hair-loss?",
          description:
            "There is absolutely no chance of hair loss for you while applying hair wigs in Delhi. The treatment that is done here at Radiance is done on your skull and out professionals avoids treating your existing hair’s root. So, no chances of hair loss remain.",
        },
        {
          title: "Can I change the style?",
          description:
            "In case of Bonding or any other type of hair wigs, you can easily change the applied hairstyle. When you reach Radiance, our professionals will be guiding you in this regards. You can apply colors on your hair, use different patches.",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Replacement System for Men's" banner="/hair-replacement-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Replacement for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairReplacementMen;
